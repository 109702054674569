import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {Paper} from '@material-ui/core';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import PhoneIcon from '@material-ui/icons/Phone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 50,
        marginTop: 50,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        maxWidth: 1300,
        padding: 5,
        borderRadius: 10
    },
    content: {
        marginTop: 20,
        marginBottom: 20,
        fontWeight: 500,
    },
    icon: {
        position: 'relative',
        top: 5
    },
    map: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50
    },
    otherElmDiv: {
        display: 'flex',
        justifyContent: 'space-around',
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        flexWrap: 'wrap',
        '& div': {
            margin: 30
        }
    },
    content2: {
        textAlign: 'left',
        margin: 5
    },
    btn: {
        background: '#0ead69'
    }
}));
const Kontakt = () => {
    const classes = useStyles();
    const sendMsg = () => {
        window.open("https://cms.webaily.com/forms/?id=pnzwojcik.pl", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=400,height=600");
    }
    return(
        <div className={classes.root}>
            <Typography variant='h3'>Kontakt</Typography>
            <Typography className={classes.content}>PNZ Wójcik Sp.j.</Typography>
            <Button startIcon={<EmailIcon/>} className={classes.btn} variant="contained" color="primary" onClick={sendMsg}>
                Napisz wiadomość
            </Button>
            <br></br>
            <Typography className={classes.content}><LocationOnIcon className={classes.icon}/> ul. Spokojna 1, 67-400 Wschowa</Typography>
            <Typography className={classes.content}><EmailIcon className={classes.icon}/> e-mail: biuro@pnzwojcik.pl</Typography>
            <Typography className={classes.content}><PhoneIcon className={classes.icon}/> tel/fax +48 65 540 35 08</Typography>
            <Typography className={classes.content}><InfoIcon className={classes.icon}/> NIP 925-18-50-746</Typography>
            <Typography className={classes.content}><InfoIcon className={classes.icon}/> REGON 971315980</Typography>
            <br></br>
            <br></br>
            <div className={classes.otherElmDiv}>
                <div>
                    <Typography variant='h5'>
                        <ShoppingCartIcon className={classes.icon}/> <b>Sklep Firmowy</b>
                    </Typography>
                    <br></br>
                    <Typography className={classes.content2}><EmailIcon className={classes.icon}/> e-mail: sklep@pnzwojcik.pl</Typography>
                    {/* <Typography className={classes.content2}><PhoneIcon className={classes.icon}/> tel/fax [chwilowy brak]</Typography> */}
                </div>
                <div>
                    <Typography variant='h5'>
                        <ShoppingCartIcon className={classes.icon}/> <b>Sklep Grene</b>
                    </Typography>
                    <br></br>
                    <Typography className={classes.content2}><EmailIcon className={classes.icon}/> e-mail: wschowa@sklepgrene.pl</Typography>
                    <Typography className={classes.content2}><PhoneIcon className={classes.icon}/> tel/fax +48 663 736 603</Typography>
                </div>
                <div>
                    <Typography variant='h5'>
                        <LocalShippingIcon className={classes.icon}/> <b>Transport</b>
                    </Typography>
                    <br></br>
                    <Typography className={classes.content2}><EmailIcon className={classes.icon}/> e-mail: transport@pnzwojcik.pl</Typography>
                    {/* <Typography className={classes.content2}><PhoneIcon className={classes.icon}/> tel/fax [chwilowy brak]</Typography> */}
                </div>
                <div>
                    <Typography variant='h5'>
                        <AccountBalanceIcon className={classes.icon}/> <b>Księgowość</b>
                    </Typography>
                    <br></br>
                    <Typography className={classes.content2}><EmailIcon className={classes.icon}/> e-mail: ksiegowosc@pnzwojcik.pl</Typography>
                </div>
            </div>
            <Paper elevation='3' className={classes.map}>
                <iframe title='googleMaps' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2466.8194657165304!2d16.317577315993788!3d51.809459296393236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470593688552e307%3A0xaedaf6b4bd5e3595!2sPNZ%20W%C3%B3jcik%20Sp.%20z%20o.o.%20Sp.%20K.!5e0!3m2!1spl!2spl!4v1594284011462!5m2!1spl!2spl' width='100%' height='400' frameBorder='0' style={{border: 0}} allowFullScreen='' aria-hidden='false' tabIndex='0'></iframe>
            </Paper>
        </div>
    )
}
export default Kontakt