import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import emailImg from '../img/email.svg';
const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 50,
        marginTop: 50,
        maxWidth: 600,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: 5,
        borderRadius: 10
    },
    title: {
        textAlign: 'center',
    },
    img: {
        maxWidth: 300,
        marginTop: 70
    },
    inpt: {
        margin: 10
    }
}));
const Poczta = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');
    const open = () => {
        if(localStorage.getItem('email-pnz-redirect') == "true"){
            window.location = 'https://mail.leszekk.eu';
        }
    }
    const verify = () => {
        const regex = /\@pnzwojcik.pl/g;
        if(regex.test(email)) {
            localStorage.setItem('email-pnz-redirect', true);
            open();
        }else{
            setErr('E-mail jest niezgodny!')
        }
    }
    useEffect(() => {
        open();
    }, [])
    return(
        <div className={classes.root}>
            <Typography className={classes.title} variant='h3'>Poczta E-Mail</Typography>
            <img className={classes.img} src={emailImg} alt='emailImg'/>
            <div>
                <TextField className={classes.inpt} label="twój e-mail" variant="outlined" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <Button className={classes.inpt} variant="contained" color="primary" onClick={verify}>
                Dalej
            </Button>
            <Typography>{err}</Typography>
        </div>
    )
}
export default Poczta