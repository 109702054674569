import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import sklep1Img from '../img/sklep-firmowy.JPG';
import sklep2Img from '../img/sklep-grene.JPG';
import sklep3Img from '../img/sklep-firmowy2.jpg';
import sklep4Img from '../img/sklep-grene2.JPG';
import visaMastercardImg from '../img/visa-mastercard.png';
import greneLogo from '../img/grene-logo.png';
import pnzLogo from '../img/logo-pnz.png';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 50,
        marginTop: 50,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        maxWidth: 1300,
        padding: 5,
        borderRadius: 10
    },
    imgHeader: {
        maxWidth: '100%',
        height: 'auto',
        marginTop: 20,
        marginBottom: 20,
        width: 600,
        [theme.breakpoints.up(1200)]: {
            margin: 20
        }
    },
    imgCreditCard: {
        width: 300,
        marginTop: 70
    },
    accordion: {
        width: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down(500)]: {
            width: 300
        }
    },
    accordionText: {
        textAlign: 'justify'
    },
    heading: {
        fontWeight: 'bold',
        fontSize: 20
    },
    greneLogo: {
        width: 310,
        marginLeft: 10,
        position: 'relative',
        top: 3,
        [theme.breakpoints.down(420)]: {
            marginLeft: 0
        }
    },
    pnzLogo: {
        height: 70,
        position: 'relative',
        top: 22,
        marginLeft: 10,
        marginRight: 10
    },
    accordionFirmowy: {
        border: '2px solid #0ead69',
        borderBottom: '1px solid #0ead69',
        borderTop: '1px solid #0ead69',
    },
    accordionGrene: {
        border: '2px solid #e3001b',
        borderBottom: '1px solid #e3001b',
        borderTop: '1px solid #e3001b',
    }
}));
const Sklep = ({sklep}) => {
    const classes = useStyles();
    const firmowy = [
        {
            id: 'Nawozy Ogrodnicze',
            description: 'Nawozy ogrodnicze  dostosowane są do  wymagań  konkretnych roślin (w tym również ozdobnych – balkonowych i domowych), po to by  dostarczyć im odpowiednich minerałów, witamin oraz  innych ważnych substancji. Dzięki nim rośliny rozwijają się zdrowo i prawidłowo, przyspieszone może zostać również ich  kiełkowanie czy owocowanie, co przekłada się na uzyskiwane plony. Profesjonalne nawozy ogrodnicze wspomagają również glebę zapobiegając jej wyjaławianiu  - co ma ogromne znaczenie zwłaszcza przy intensywnej uprawie. W naszej ofercie znajdziecie Państwo nawozy - uniwersalne, długodziałające, jedno  i wieloskładnikowe, do owoców i warzyw, do iglaków, do kwiatów balkonowych i doniczkowych, do roślin kwitnących,  do storczyków i traw, organiczne, jesienne i wiele innych.'
        },
        {
            id: 'Środki Ochrony Roślin',
            description: 'Środki ochrony roślin to produkty pomocne w walce ze szkodnikami i chorobami roślin, które dotykają zarówno duże uprawy, jak i ogródki czy rośliny doniczkowe. Nie muszą to być substancje groźne dla środowiska – coraz częściej stosuje się ekologiczne środki ochrony roślin, które są równie skuteczne co ich standardowe odpowiedniki. W naszej ofercie znajdziecie Państwo - herbicydy (preparaty chwastobójcze), fungicydy(preparaty grzybobójcze), preparaty do walki z owadami lub insektami (muchy, przędziorek, stonka, mszyce, …).'
        },
        {
            id: 'Narzędnia Ogrodnicze',
            description: 'W naszej ofercie znajdziecie Państwo produkty pomagające zadbać o ład i porządek w ogrodzie oraz dobrą kondycję roślin. Oferujemy m.in.: sekatory i nożyce ogrodowe, siekiery, opryskiwacze, widły, łopaty, grabie, trzonki do grabi.'
        },
        {
            id: 'Akcesoria Ogrodowe',
            description: 'Ogród to efekt wielu dobrych pomysłów, ale ich realizacja wymaga wielokrotnie zastosowania specjalnych mat, podpór do roślin, taśm samozaciskowych czy też bardzo znanej agrowłókniny.  Wszystkie to znajdziecie Państwo w naszym sklepie. W naszej ofercie są również wiadra, konewki czy kompostowniki.'
        },
        {
            id: 'Dom i Warsztat',
            description: 'm.in.: akcesoria kuchenne, środki czystości, odzież roboczą, myjki ciśnieniowe i wiele więcej.'
        },
        {
            id: 'Uprawa Roślin',
            description: 'm.in.: nasiona, ziemię ogrodową, agrowłókninę, cebulki.'
        },
        {
            id: 'Nasiona Trawy',
            description: 'wiele rodzajów traw, dostosowanych do prawie każdych warunków.'
        },
        {
            id: 'Zwalczanie Szkodników',
            description: 'm.in.: preparaty na owady, odstraszacze zwierząt, produkty do deratyzacji, siatki i zabezbieczenia.'
        },
        {
            id: 'Nawadnianie Ogrodu',
            description: 'm.in.: zraszacze, węże ogrodowe, konewki i wiele więcej.'
        },
        {
            id: 'Doniczki',
            description: 'm.in.: doniczki ozdobne, jak i również doniczki produkcyjne.'
        },
        {
            id: 'Znicze',
            description: 'W naszej ofercie znajdą Państwo szeroką gamę profesjonalnych zniczy.'
        },
        {
            id: 'Kwiaty Sztuczne',
            description: 'm.in. wiele sztucznych, jednak pięknych ozdobnych kwiatów.'
        },
    ];
    const grene = [
        {
            id: 'Części',
            description: 'Części do ciągników, Części do kombajnów, Filtry do maszyn i ciągników, Części do sieczkarni, Części do maszyn uprawowych, Części do opryskiwaczy, Części do przetrząsaczo-zgrabiarek, Cześci do kosiarek'
        },
        {
            id: 'Odzież Robocza',
            description: 'Profesjonana odzież robocza (atestowana), Obuwie, Artykuły BHP'
        },
        {
            id: 'Pojazdy',
            description: 'Oświetlenie pojazdów, Koła, gąsienice i ogumienie, Wyposażenie elektryczne, Wyposażenie kabiny i karoseria, Węże techniczne i akcesoria, Produkty smarne i konserwacyjne, Chemia, kosmetyki i akcesoria do pielęgnacji, Technika paliwowa'
        },
        {
            id: 'Gospodarstwo',
            description: 'Artykuły ogrodnicze, Artykuły gospodarcze, Elektronika w rolnictwie, Warsztat, Artykuły złączne, Akcesoria remontowo-budowlane, Artykuły motoryzacyjne'
        },
        {
            id: 'Zootechnika',
            description: 'Hodowla bydła, Hodowla trzody chlewnej, Hodowla owiec, Hodowla koni, Hodowla królików, Hodowla drobiu, Hodowla zwierząt towarzyszących, Elektryczne ogrodzenia pastwisk'
        },
        {
            id: 'Transmisja',
            description: 'Wały, Pasy napędowe, Łożyska i akcesoria łożyskowe, Łańcuchy napędowe, Koła łańcuchowe i zębate, Przekładnie, Liniowe napędy nastawcze, Uszczelnienia'
        },
        {
            id: 'Hydraulika',
            description: 'Pompy hydrauliczne, Silniki, rotatory i zawory skrętu, Rozdzielacze i zawory, Siłowniki, Szybkozłącza i szybkosprzęgi, Przewody, węże, rury, Technika pomiarowa, Zasilacze hydrauliczne i akcesoria'
        },
        {
            id: 'Zabawki',
            description: 'Realistyczne, wysokiej jakości zabawki o tematyce rolniczej (modele ciągników, traktorów, przyczep,  ...). Idealne jako prezent dla najmłodszych.'
        }
    ];
    return(
        <div className={classes.root}>
            {sklep === 'firmowy' || sklep === undefined ? (
                <React.Fragment>
                    <Typography variant='h3'>
                        Sklep
                        <img src={pnzLogo} alt='pnz logo' className={classes.pnzLogo}/>
                        Firmowy
                    </Typography>
                    <img className={classes.imgHeader} src={sklep1Img} alt='sklep'/>
                    <img className={classes.imgHeader} src={sklep3Img} alt='sklep'/>
                    <div className={classes.accordion}>
                        {firmowy.map(item => 
                            <Accordion className={classes.accordionFirmowy} key={item.id}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                >
                                <Typography className={classes.heading}>{item.id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography className={classes.accordionText}>
                                    {item.description}
                                </Typography>
                                </AccordionDetails>
                            </Accordion> 
                        )}
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                </React.Fragment>
            ) : null}
            {sklep === 'grene' || sklep === undefined ? (
                <React.Fragment>
                    <Typography variant='h3' id='grene'>
                        Sklep 
                        <img src={greneLogo} alt='grene' className={classes.greneLogo}/>
                    </Typography>
                    <img className={classes.imgHeader} src={sklep2Img} alt='sklep'/>
                    <img className={classes.imgHeader} src={sklep4Img} alt='sklep'/>
                    <div className={classes.accordion}>
                        {grene.map(item => 
                            <Accordion className={classes.accordionGrene} key={item.id}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                >
                                <Typography className={classes.heading}>{item.id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography className={classes.accordionText}>
                                    {item.description}
                                </Typography>
                                </AccordionDetails>
                            </Accordion> 
                        )}
                    </div>
                </React.Fragment>
            ) : null}

            <img className={classes.imgCreditCard} src={visaMastercardImg} alt='card'/>
        </div>
    )
}
export default Sklep