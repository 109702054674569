import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import gsap from 'gsap';
const useStyles = makeStyles(theme => ({
    paper: {
        width: 120,
        height: 120,
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#0ead69',
    },
    paperTitle: {
        textAlign: 'center',
        fontWeight: 400,
        color: '#fff'
    },
    paperDescription: {
        margin: 10,
        maxWidth: 200,
        textAlign: 'center'
    },
    papers: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    paperDiv: {
        margin: 30
    }
}));
const OfirmieAnimation = () => {
    const classes = useStyles();
    useEffect(() => {
        gsap.from('.paperDivA', {
            duration: 2,
            scale: 0.9, 
            opacity: 0, 
            delay: 0.5, 
            stagger: 0.2,
            ease: 'elastic', 
            force3D: true
        });
    }, []);
    return(
        <div className={classes.papers}>
            <div className={`${classes.paperDiv} paperDivA`}>
                <Typography variant='h5' className={classes.paperDescription}>OD</Typography>
                <Paper className={classes.paper} elevation={3}>
                    <Typography className={classes.paperTitle}  variant='h3'>{(new Date().getFullYear() - 2000)}</Typography>
                </Paper>
                <Typography variant='h5' className={classes.paperDescription}>LAT NA RYNKU</Typography>
            </div>
            <div className={`${classes.paperDiv} paperDivA`}>
                <Typography variant='h5' className={classes.paperDescription}>OD POCZĄTKU</Typography>
                <Paper className={classes.paper} elevation={3}>
                    <Typography className={classes.paperTitle}  variant='h3'>100%</Typography>
                </Paper>
                <Typography variant='h5' className={classes.paperDescription}>KAPITAŁ POLSKI</Typography>
            </div>
        </div>
    )
}
export default OfirmieAnimation