import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import logo from '../logo.png';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MenuEl from '@material-ui/core/Menu';
import MenuElItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 0,
      marginLeft: 5,
      display: 'none'
    },
    logo: {
        height: 50,
        position: 'relative',
        top: 5
    },
    appbar: {
        paddingTop: 5,
        paddingBottom: 5,
        background: '#1b1f1d',
    },
    logoTitle: {
        marginLeft: 15,
        fontSize: 25,
        position: 'relative',
        bottom: 9
    },
    title: {
      flexGrow: 1,
    },
    home: {
        color: '#fff'
    },
    list: {
        width: '200px',
        paddingRight: 10
    },
    link: {
        color: '#212121'
    },
    menuSubIcon: {
        color: '#219653'
    },
    menuBtn: {
        color: '#fff',
        fontSize: 18,
        margin: '0px 5px 0px 5px'
    },
    [theme.breakpoints.down(1300)]: {
        menuElDesktop: {
            display: 'none'
        },
        menuButton: {
            display: 'block'
        }
    },
    menuElBtn: {
        padding: 10,
        fontWeight: 500
    }

}));
const Menu = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(null);
    useEffect(() => {
        window.fetch('https://cms.webaily.com/api/article.php/?id=97')
        .then(res => res.json())
        .then(data => setItems(data.content))
        .catch(err => window.alert("Przepraszamy, wystąpił błąd podczas ładowania Menu."))
    }, []);

    return (
        <div className={classes.root}>
            <AppBar position='fixed' className={classes.appbar}>
                <Toolbar>
                    <Typography variant='h6' className={classes.title}>
                        <Link className={classes.home} href='/'>
                            <img src={logo} alt='logo' className={classes.logo}/>
                            <span className={classes.logoTitle}>PNZ Wójcik</span>
                        </Link>
                    </Typography>
                    <React.Fragment>
                        <IconButton edge='start' className={classes.menuButton} id='menuI' color='inherit' aria-label='contact' href='/kontakt'>
                            <PhoneIcon style={{fontSize: 35}} />
                        </IconButton>
                        <IconButton onClick={() => setOpen(true)} id='menuI' edge='start' className={`${classes.menuButton} menuButton`} color='inherit' aria-label='menu'>
                            <MenuIcon style={{fontSize: 35}} />
                        </IconButton>
                    </React.Fragment>
                    {items !== null &&
                        <React.Fragment>
                            {items.map(item =>
                                <div id='menuI' key={item.url}>
                                    {item.menu !== undefined ? (
                                        <div className={classes.menuElDesktop}>
                                            <PopupState variant="popover" popupId="menu">
                                                {(popupState) => (
                                                    <React.Fragment>
                                                        <Button className={`${classes.menuBtn} menuBtnDesktop`} {...bindTrigger(popupState)}>
                                                            {item.name}
                                                        </Button>
                                                        <MenuEl className='menuEl' {...bindMenu(popupState)}>
                                                            {item.menu.map(el => 
                                                                <MenuElItem key={el.url} className={classes.menuElBtn} onClick={() => window.location = el.url}>{el.name}</MenuElItem>
                                                            )}
                                                        </MenuEl>
                                                    </React.Fragment>
                                                )}
                                            </PopupState>
                                        </div>
                                    ) : (
                                        <div key={item.url} className={classes.menuElDesktop}>
                                            <Button className={classes.menuBtn} href={item.url}>{item.name}</Button>
                                        </div>  
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    }

                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={() => setOpen(false)}>
                {items !== null &&
                <List className={classes.list}>
                    {items.map(item =>
                        <React.Fragment key={item.url}>
                            {item.menu !== undefined ? (
                                <PopupState variant="popover" popupId="menu">
                                    {(popupState2) => (
                                        <React.Fragment>
                                            <Link className={`${classes.link} menuBtnMobile`} {...bindTrigger(popupState2)}>
                                                <ListItem button>
                                                    <ChevronRightIcon className={classes.menuSubIcon}/>
                                                    <Typography variant="h6">{item.name}</Typography>
                                                </ListItem>
                                            </Link>
                                            <MenuEl className='menuElMobile' {...bindMenu(popupState2)}>
                                                {item.menu.map(el => 
                                                    <Button key={el.url} href={el.url} className={classes.menuElBtn} onClick={popupState2.close}>{el.name}</Button>
                                                )}
                                            </MenuEl>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            ) : (
                                <Link key={item.url} className={classes.link} href={item.url}>
                                    <ListItem button>
                                        <ChevronRightIcon className={classes.menuSubIcon}/>
                                        <Typography variant="h6">{item.name}</Typography>
                                    </ListItem>
                                </Link>
                            )}
                        </React.Fragment>
                    )}
                </List>
                }
            </Drawer>
        </div>
    );
}
export default Menu;