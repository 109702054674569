import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import gsap from 'gsap';
import OfirmieAnimation from '../components/OfirmieAnimation';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'justify',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 50,
        marginTop: 50,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        maxWidth: 1300,
        padding: 10,
        borderRadius: 10
    },
    title: {
        textAlign: 'center',
    },
    subTitle: {
        margin: 20,
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
}));
const OFirmie = () => {
    const classes = useStyles();
    useEffect(() => {
        gsap.from('.subTitleA', {
            duration: 2,
            opacity: 0,
            delay: 1.5,
            ease: 'elastic', 
            x: '-30'
        }) 
    }, []);
    return(
        <div className={classes.root}>
            <Typography className={classes.title} variant='h3'>O Firmie</Typography>
            <OfirmieAnimation/>
            <Typography className={`${classes.subTitle} subTitleA`}>
                Od początku naszej działalności działamy jako spółka rodzinna. Naszym głównym celem jest kompleksowe zaopatrzenie rolnictwa, ogrodnictwa oraz pomoc działkowiczom. Mając na uwadze zmieniające się potrzeby naszych Klientów, inwestujemy w wiedzę naszych pracowników, nowoczesne zaplecze techniczne, oraz w nową infrastrukturę.
                Wieloletnie doświadczenie oraz praktyka pozwala nam na profesjonalne doradztwo oraz zrozumienie różnorodnych potrzeb Naszych Kontrahentów, a także dopasowanie oferty dla najbardziej wymagających.
                Pragniemy dzielić się z Państwem swoją wiedzą oraz doświadczeniem, abyśmy wspólnie mogli osiągnąć jak najwyższe plony.
            </Typography>
        </div>
    )
}
export default OFirmie