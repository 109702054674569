import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import '../PostPage.css';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        marginTop: 50,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 800,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: 5,
        borderRadius: 10
    },
    btn: {
        margin: 10,
        background: '#0ead69'
    },
    center: {
        textAlign: 'center'
    }
}));
const PostPage = ({match,preId}) => {
    const classes = useStyles();
    let id;
    if(preId){
        id = preId
    }else{
        id = match.params.id;
    }
    

    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const getContent = () => {
        setIsLoading(true);
        window.fetch(`https://cms.webaily.com/api/article.php/?id=${id}`)
        .then(res => res.json())
        .then(data => {
            if(data.category == 'pnzwojcik' || data.category == 'pnzwojcik-2'){
                setResponse(data)
                setIsLoading(false);
            }else{
                setError(true);
                setIsLoading(false);
            }
        })
        .catch(err => {
            setError(true);
            setIsLoading(false);
        })
    }
    useEffect(() => {
        if(id){
            getContent();
        }
    }, []);
    return(
        <div className={classes.root}>
            {!isLoading && response !== null ? (
                <>
                    <h1 className={classes.center}>{response.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: response.content}} className='content'></div>
                    <hr></hr>
                    <p className={classes.center}>Zaktualizowano {response.time}</p>
                    {!preId && (
                        <Button href='/aktualnosci' className={classes.btn} variant="contained" color="primary">Powrót</Button>
                    )}
                </>
                
            ): (
                null
            )}   
            {isLoading ? (
                <CircularProgress/>
            ): ( null)}
            {error ? (
                <h3>404 - Nie znaleziono artykułu</h3>
            ) : (null)}
        </div>
    )
}
export default withRouter(PostPage)