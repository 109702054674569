import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        marginBottom: 50,
        marginTop: 50,
        maxWidth: 600,
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: 5,
        borderRadius: 10
    },
    title: {
        textAlign: 'center',
        marginBottom: 50,
        marginTop: 5
    },
    btn: {
        background: '#0ead69'
    },
}));
const Aktualnosci = () => {
    const classes = useStyles();
    const [response, setResponse] = useState([])
    useEffect(() => {
        window.fetch('https://cms.webaily.com/api/articles.php/?website=pnzwojcik')
        .then(res => res.json())
        .then(data => setResponse(data))
        .catch(err => window.alert("Przepraszamy, wystąpił błąd podczas ładowania treści."))
    }, []);
    return(
        <div className={classes.root}>
            <Typography className={classes.title} variant='h3'>Aktualności</Typography>
            <div>
                {response.length && response.success == undefined ? (
                    response.map(obj => {
                        return(
                            <Card style={{margin: 10, border: '2px solid #0ead69'}} className={classes.card} key={obj.id}>
                                <CardContent>
                                    <Typography style={{color: '#000'}} variant='h4' color="textSecondary">
                                        {obj.title}
                                    </Typography>
                                    <p>zaktualizowano {obj.time}</p>
                                </CardContent>
                                <CardActions>
                                    <Button className={classes.btn} variant="contained" color="primary" href={`/aktualnosci/${obj.id}`} size="large">Zobacz</Button>
                                </CardActions>
                            </Card>
                        )
                    })
                ) : (
                    <CircularProgress />
                )}
                {response.success == false &&
                    <h2>Wystąpił błąd!</h2>
                }
            </div>
        </div>
    )
}
export default Aktualnosci