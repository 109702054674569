import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Analytics from 'react-router-ga';
import Home from '../pages/Home';
import Menu from '../components/Menu';
// import Transport from '../pages/subPages/Transport';
import Sklep from '../pages/Sklep';
// import MaterialSiewny from '../pages/subPages/MaterialSiewny';
// import MonitorPage from '../pages/subPages/MonitorPage';

import Kontakt from '../pages/Kontakt';
import OFirmie from '../pages/OFirmie';
import Poczta from '../pages/Poczta';
// import Nawozy from '../pages/subPages/Nawozy';
// import UslugiRolnicze from '../pages/subPages/UslugiRolnicze';
// import SrodkiOchronyRoslin from '../pages/subPages/SrodkiOchronyRoslin';
// import PowierzchniaMagazynowa from '../pages/subPages/PowierzchniaMagazynowa';
import SkupZboz from '../pages/subPages/SkupZboz';
// import Certyfikaty from '../pages/Certyfikaty';
import Aktualnosci from '../pages/Aktualnosci';
import PostPage from '../pages/PostPage';

import MarginElm from '../components/MarginElm';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const Routers = () => {
    const trackingID = 'UA-175838672-1'
    return(
        <React.Fragment>
            <Router>
                <Menu/>
                <MarginElm/>
                <Analytics id={trackingID}>
                    <Switch>
                        <Route path='/godziny-otwarcia'>
                            <PostPage preId='120'/>
                        </Route>
                        <Route path='/nawozy'>
                            {/* <Nawozy/> */}
                            <PostPage preId='79'/>
                        </Route>
                        <Route path='/srodki-ochrony-roslin'>
                            {/* <SrodkiOchronyRoslin/> */}
                            <PostPage preId='83'/>
                        </Route>
                        <Route path='/wynajem-powierzchni-magazynowej'>
                            {/* <PowierzchniaMagazynowa/> */}
                            <PostPage preId='99'/>
                        </Route>
                        <Route path='/transport'>
                            {/* <Transport/> */}
                            <PostPage preId='98'/>
                        </Route>
                        <Route path='/sklep/grene'>
                            <Sklep sklep='grene'/>
                        </Route>
                        <Route path='/sklep/firmowy'>
                            <Sklep sklep='firmowy'/>
                        </Route>
                        <Route path='/sklep'>
                            <Sklep/>
                        </Route>
                        <Route path='/material-siewny'>
                            {/* <MaterialSiewny/> */}
                            <PostPage preId='78'/>
                        </Route>
                        <Route path='/skup-zboz'>
                            <SkupZboz/>
                        </Route>
                        <Route path='/uslugi-rolnicze'>
                            {/* <UslugiRolnicze/> */}
                            <PostPage preId='100'/>
                        </Route>
                        <Route path='/certyfikaty'>
                            {/* <Certyfikaty/> */}
                            <PostPage preId='101'/>
                        </Route>
                        <Route path='/kontakt'>
                            <Kontakt/>
                        </Route>
                        <Route path='/o-firmie'>
                            <OFirmie/>
                        </Route>
                        <Route path='/aktualnosci/:id'>
                            <PostPage/>
                        </Route>
                        <Route path='/aktualnosci'>
                            <Aktualnosci/>
                        </Route>
                        <Route path='/poczta'>
                            <Poczta/>
                        </Route>
                        {/* <Route path='/tv'>
                            <MonitorPage/>
                        </Route> */}
                        <Route path='/'>
                            <Home/>
                        </Route>
                    </Switch> 
                </Analytics>
                <MessengerCustomerChat
                    pageId="102783012015228"
                    appId="514949726379685"
                    language="pl_PL"
                />
            </Router>
        </React.Fragment>
    )
}
export default Routers;