import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Tabletop from 'tabletop';

import Footer from '../components/Footer';
import Link from '@material-ui/core/Link';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';

import logoImg from '../img/logo.png';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import skupZbozImg from '../img/skup-zboz.png';
import powierzchniaMagazynowa3Img from '../img/powierzchnia-magazynowa3.JPG';
import nawozy2Img from '../img/nawozy2.jpg';

gsap.registerPlugin(ScrollTrigger)

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 150,
    },
    headerTitle: {
        fontWeight: 'bold',
        color: '#fff',
        textAlign: 'left'
    },
    headerTitleDiv: {
        marginLeft: 50,
        marginTop: 40
    },
    headerImg: {
        marginLeft: 150
    },
    contactHeaderItem: {
        margin: 5,
        color: '#fff',
        fontSize: 20
    },
    [theme.breakpoints.down(1050)]: {
        headerImg: {
            marginLeft: 20
        },
        contactHeaderItem: {
            fontSize: 15
        }
    },
    [theme.breakpoints.down(850)]: {
        header: {
            marginTop: 50
        }
    },
    [theme.breakpoints.down(800)]: {
        header: {
            justifyContent: 'center',
        },
        headerImg: {
            margin: 0
        },
        headerTitleDiv: {
            margin: 0,
            marginTop: 20,
            width: '100%'
        },
        headerTitle: {
            textAlign: 'center'
        }
    },
    contactHeader: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },

    headerIcon: {
        position: 'relative',
        top: 5
    },
    offer: {
        marginTop: '30vh',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        maxWidth: 1200,
        borderRadius: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        padding: '25px 15px 25px 15px',
        marginBottom: 50
    },
    offerDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 10,
        alignItems: 'center'
    },
    offerTitle: {
        margin: '10px 0px 10px 0px',
        fontWeight: 400
    },
    offerHeader: {
        textAlign: 'left',
        height: '100%'
    },
    offerHeaderDiv: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '30%'
    },
    image: {
        width: 250,
        height: 310,
        color: '#fff',
        margin: 10,
        borderRadius: 10,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&:hover': {
            transform: 'scale(0.97)',
            transition: 'transform 0.3s'
        }
    },
    [theme.breakpoints.down(1200)]: {
        offer: {
            flexDirection: 'column'
        },
        offerHeader: {
            marginBottom: 20,
            textAlign: 'center'
        },
        offerHeaderDiv: {
            justifyContent: 'center',
            maxWidth: '100%'
        }
    },
    [theme.breakpoints.down(750)]: {
        offerDiv: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: 0
        },
        image: {
            width: '90%'
        }
    },
    imgTitle: {
        fontSize: 30,
        fontWeight: 600,
    },
    offerReadMore: {
        fontSize: 20,
        cursor: 'pointer',
        fontWeight: 500
    },
    paper: {
        width: 100,
        height: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#0ead69',
    },
    paperTitle: {
        textAlign: 'center',
        fontWeight: 400,
        color: '#fff'
    },
    paperDescription: {
        margin: 10,
        maxWidth: 200,
        textAlign: 'center'
    },
    papers: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%'
    },
    paperDiv: {
        margin: 30
    },
    costSkup: {
        border: '2px solid #3f51b5',
        borderRadius: 10,
        minHeight: 110
    },
    costSkupDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '2px solid #3f51b5',
        borderBottom: '2px solid #3f51b5'
    },
    costSkupItem: {
        margin: 10,
        fontWeight: 500,
    },
    costSkupInfo: {
        margin: 5
    }

}));
const Home = () => {
    const classes = useStyles();
    const [cost, setCost] = useState('');
    useEffect(() => {
        gsap.from('.headerImg', {
            x: -100,
            delay: 0.5,
            duration: 0.5,
            opacity: 0,
            scrollTrigger: '.header'
        });
        gsap.from('.headerTitleDiv', {
            y: -100,
            delay: 1,
            duration: 0.5,
            opacity: 0,
            scrollTrigger: '.header'
        });
        gsap.from('.offer1', {
            y: 100,
            duration: 2,
            opacity: 0,
            scrollTrigger: {
                trigger: '.offer1',
                start: '-20% 50%',
                end: '-20% 50%',
                scrub: 1,
            }
        });
        gsap.from('.offer2', {
            y: 100,
            duration: 2,
            opacity: 0,
            scrollTrigger: {
                trigger: '.offer2',
                start: 'top bottom',
                end: 'top bottom',
                scrub: 1,
            }
        });
        Tabletop.init({
            key: '1zhiGw73f06T8oYDiO738031gYwM8rVOzuUGXlx79wPg',
            callback: googleData => {
                if(googleData){
                    const filter = googleData.filter(el => {
                        return el.isEnable === 'TRUE'
                    })
                    setCost(filter);
                }
            },
            simpleSheet: true,
        })
    }, []);
    const openMenu = async () => {
        if(window.innerWidth < 1300){
            await document.querySelector('.menuButton').click();
            await document.querySelectorAll('.menuBtnMobile')[1].click();
        }else{
            document.querySelectorAll('.menuBtnDesktop')[1].click();
        }
    }
    const images = [
        {
            title: 'Skup Zbóż',
            img: skupZbozImg,
            url: '/skup-zboz'
        },
        {
            title: 'Magazynowanie',
            img: powierzchniaMagazynowa3Img,
            url: '/wynajem-powierzchni-magazynowej'
        },
        {
            title: 'Nawozy',
            img: nawozy2Img,
            url: '/nawozy'
        }
    ]
    return(
        <div className={classes.root}>
            <div>
                <div className={`${classes.header} header`}>
                    <img src={logoImg} alt='logo' className={`${classes.headerImg} headerImg`}/>
                    <div className={`${classes.headerTitleDiv} headerTitleDiv`}>
                        <Typography variant='h1' className={classes.headerTitle}>PNZ Wójcik</Typography>
                        <div className={classes.contactHeader}>
                            <Link href='mailto:biuro@pnzwojcik.pl' className={classes.contactHeaderItem}><EmailIcon className={classes.headerIcon}/> biuro@pnzwojcik.pl</Link>
                            <Link href='tel:+48 65 540 35 08' className={classes.contactHeaderItem}><PhoneIcon className={classes.headerIcon}/> +48 65 540 35 08</Link>
                            <Link href='https://g.page/pnz-wojcik' className={classes.contactHeaderItem}><LocationOnIcon className={classes.headerIcon}/> ul. Spokojna 1, Wschowa</Link>
                        </div>
                    </div>
                </div>
                <div className={`${classes.offer} offer1`}>
                    <div className={classes.offerHeaderDiv}>
                        <div className={classes.offerHeader}>
                            <Typography className={classes.offerTitle} variant='h2'>Nasza Oferta</Typography>
                            <Typography>Zachęcamy do szczegółowego zapoznania się z naszą ofertą.</Typography>
                            <br></br>
                            <Link className={classes.offerReadMore} onClick={openMenu}>Zobacz więcej</Link>
                            <br></br>
                            <br></br>
                            {cost.length ? (
                                <div className={classes.costSkup}>
                                    <Typography className={classes.costSkupInfo}>Aktualny cennik skupu (NETTO / 1t)</Typography>
                                    <div className={classes.costSkupDiv}>
                                        {cost.map(item => 
                                            <Typography className={classes.costSkupItem}>{item.title} - {item.cost}</Typography>
                                        )}
                                    </div>
                                    <Typography className={classes.costSkupInfo}>Data aktualizacji: {cost[0].date}</Typography>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className={classes.offerDiv}>
                        {images.map(el => 
                            <ButtonBase
                                key={el.title}
                                className={classes.image}
                                href={el.url}
                                style={{
                                    backgroundImage: `url(${el.img})`,
                                }}
                            >
                                <Typography className={classes.imgTitle}>
                                    {el.title}
                                </Typography>
                            </ButtonBase>
                        )}
                    </div>
                </div>
                <div className={`${classes.offer} offer2`}>
                    <div className={classes.offerHeaderDiv}>
                        <div className={classes.offerHeader}>
                            <Typography className={classes.offerTitle} variant='h2'>O Firmie</Typography>
                            <Typography>Naszym głównym celem jest kompleksowe zaopatrzenie rolnictwa, ogrodnictwa oraz pomoc działkowiczom.</Typography>
                            <br></br>
                            <Link className={classes.offerReadMore} href='/o-firmie'>Zobacz więcej</Link>
                        </div>  
                    </div>
                    <div className={classes.offerDiv}>
                        <div className={classes.papers}>
                            <div className={`${classes.paperDiv} paperDivA`}>
                                <Typography variant='h6' className={classes.paperDescription}>OD</Typography>
                                <Paper className={classes.paper} elevation={3}>
                                    <Typography className={classes.paperTitle}  variant='h4'>{(new Date().getFullYear() - 2000)}</Typography>
                                </Paper>
                                <Typography variant='h6' className={classes.paperDescription}>LAT NA RYNKU</Typography>
                            </div>
                            <div className={`${classes.paperDiv} paperDivA`}>
                                <Typography variant='h6' className={classes.paperDescription}>OD POCZĄTKU</Typography>
                                <Paper className={classes.paper} elevation={3}>
                                    <Typography className={classes.paperTitle}  variant='h4'>100%</Typography>
                                </Paper>
                                <Typography variant='h6' className={classes.paperDescription}>KAPITAŁ POLSKI</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Home