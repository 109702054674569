import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import skupZbozImg from '../../img/skup-zboz.png';
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 50,
        marginTop: 50,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        maxWidth: 600,
        padding: 5,
        borderRadius: 10
    },
    content: {
        fontSize: 20
    },
    title: {
        marginBottom: 30
    },
    subTitle: {
        margin: 40
    },
    img: {
        maxWidth: '100%',
        height: 'auto',
        marginTop: 20,
        marginBottom: 20,
        width: 500,
        [theme.breakpoints.up(610)]: {
            margin: 40
        }
    },
    option: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        margin: 10,
        border: '2px solid #4CAF50',
        maxWidth: 300,
        borderRadius: 5
    },
    optionTitle: {
        marginRight: 5
    },
    optionCost: {
        color: '#f44336',
        fontWeight: 'bold',
    },
    icon: {
        position: 'relative',
        top: 6,
        marginRight: 5
    }
}));
const SkupZboz = () => {
    const classes = useStyles();
    const [cost, setCost] = useState('');
    const [info, setInfo] = useState('Dostępny w biurze naszej firmy')
    // useEffect(() => {
    //     Tabletop.init({
    //         key: '1zhiGw73f06T8oYDiO738031gYwM8rVOzuUGXlx79wPg',
    //         callback: googleData => {
    //             if(googleData){
    //                 const filter = googleData.filter(el => {
    //                     return el.isEnable === 'TRUE'
    //                 })
    //                 setCost(filter);
    //                 if(!filter.length){
    //                     setInfo('Brak danych!');
    //                 }
    //             }
    //         },
    //         simpleSheet: true,
    //     })
    // }, []);
    return(
        <div className={classes.root}>
            <Typography className={classes.title} variant='h3'>Skup Zbóż</Typography>
            <div className={classes.costDiv}>
                <Typography variant='h5'>Aktualny cennik skupu</Typography>
                <Typography>NETTO / 1t</Typography>
                <br></br>
                {cost.length ? (
                    <React.Fragment>
                        {cost.map(item =>
                            <div key={item.title} className={classes.option}>
                                <Typography variant='h6' className={classes.optionTitle}>{item.title} - </Typography>
                                <Typography variant='h6' className={classes.optionCost}>{item.cost}</Typography>
                            </div>

                        )}
                        <br></br>
                        <Typography>
                            <HistoryIcon className={classes.icon}/>
                            Data aktualizacji: {cost[0].date}
                        </Typography>
                    </React.Fragment>
                ) : (
                    <div>
                        <br></br>
                        <Typography>{info}</Typography>
                    </div>
                )}

            </div>
            <br></br>
            <br></br>
            <Typography className={classes.content}>
                W trakcie opracowania...
            </Typography>
            <img className={classes.img} src={skupZbozImg} alt='skupImg'/>
        </div>
    )
}
export default SkupZboz