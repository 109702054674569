import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingTop: 5,
        background: 'rgba(255, 255, 255, 0.7)',
        color: '#000',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        position: 'relative',
        bottom: 0,
        marginTop: 100,
    },
    info: {
        margin: 10,
        fontWeight: 500,
        marginRight: 90
    },
    link: {
        marginLeft: 5,
        fontWeight: 500
    },
    cookieInfo: {
        background: 'rgba(255, 255, 255, 0.7)',
        margin: 0,
        textAlign: 'left',
        padding: 10
    }
}));
const Footer = () => {
    const classes = useStyles();
    return(
        <>
            <div className={classes.root}>
                <Typography className={classes.info}>
                    {(new Date().getFullYear())} &copy; PNZ Wójcik
                </Typography>
                <Typography className={classes.info}>
                    Projekt i realizacja:
                    <Link style={{textDecoration: 'underline'}} className={classes.link} href='https://www.webaily.com' color='inherit'>
                        &copy; Webaily.com
                    </Link>
                </Typography>
            </div>
            <p className={classes.cookieInfo}>System przetwarza ogólne dane osobowe i korzysta z plików cookies w celu zapewnienia działania usługi. Korzystanie ze strony oznacza zgodę na to.</p>
        </>
    )
}
export default Footer